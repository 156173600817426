<template>
  <vx-table :columns="columns" :resource="resource" :filters="filters" />
</template>

<script>
import {
  VxCellDate,
  VxCellDrop,
  VxCellLink,
  VxCellMoney,
  VxCellPercentage,
  VxCellPlayerAvatar,
  VxTable
} from '@/components/table'
import { upgrades, passDataToResource, upgradeWinMap } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { map } from 'lodash'
import { splitText } from '@/services/utils'
import { buildRoute, path } from '@/router'

export default {
  name: 'Upgrades',
  components: {
    VxTable,
    VxCellPlayerAvatar,
    VxCellDate,
    VxCellMoney,
    VxCellPercentage
  },
  setup () {
    const resource = passDataToResource(upgrades.getAll, {
      requestParams: {
        params: { include: 'roll,betDrops,wonDrops,desiredItems,player' }
      }
    })

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        label: 'Player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'won_drops',
        component: VxCellDrop,
        tdAttr: {
          showPrice: true
        }
      },
      {
        key: 'bet_drops',
        component: VxCellDrop,
        tdAttr: {
          showPrice: true
        }
      },
      {
        key: 'main_bet_price',
        label: 'Sum of Bet Drops',
        component: VxCellMoney
      },
      {
        key: 'is_win',
        formatter: (value) => upgradeWinMap[Number(value)]
      },
      {
        key: 'chance',
        sortable: true,
        component: VxCellPercentage
      },
      {
        key: 'winning_ranges',
        formatter: (value) => {
          return splitText(value.map((range) => `${range.from} - ${range.to}`))
        }
      },
      {
        key: 'roll.id',
        label: 'Roll',
        component: VxCellLink,
        tdAttr: (id) => ({
          label: `#${id}`,
          href: buildRoute(path.rolls, { query: { id } })
        })
      },
      {
        key: 'created_at',
        component: VxCellDate
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'bet_drop_id',
        type: filterTypes.number
      },
      {
        key: 'won_drop_id',
        type: filterTypes.number
      },
      {
        key: 'won_item_title',
        type: filterTypes.text
      },
      {
        key: 'is_win',
        type: filterTypes.select,
        options: map(upgradeWinMap, (label, value) => ({ label, value })),
        optionsLabel: 'label',
        reduce: ({ value }) => value
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from', 'to']
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
